import { css } from 'astroturf';
import React, { useEffect, useRef, useState } from 'react';
import { ArrowRight } from 'react-bytesize-icons';
import ReactTooltip from 'react-tooltip';
import Link from '../link';
import useOnClickOutside from './useOnClickOutside';

import releaseData from '../../data/releaseData';

const styles = css`
  .releaseWrapper {
    position: relative;
    opacity: 1;
    transition: 2s ease-in-out opacity;

    @media (max-width: 480px) {
      display: none;
    }
  }
  .releaseNotifier {
    position: relative;
    border: 0;
    background: none;
    width: 35px;
    height: 35px;
    color: #007ca5;
    display: grid;
    place-items: center;
    border-radius: 35px;
    transition: 0.25s ease-in-out background;
    opacity: 0.5;

    &.highlight {
      opacity: 1;
    }

    &:hover,
    &:focus-visible {
      opacity: 1;
      background: #007ca522;
    }

    & svg {
      pointer-events: none;
      width: 24px;
      height: 24px;

      @media (forced-colors: active) {
        stroke: LinkText;
      }
    }
    & span {
      pointer-events: none;
      background: #b00;
      color: #fff;
      width: 14px;
      height: 14px;
      border-radius: 20px;
      display: block;
      text-align: center;
      font-size: 11px;
      line-height: 15px;
      font-weight: bold;
      position: absolute;
      right: 2px;
      bottom: 6px;
      opacity: 0;
      transition: 0.25s ease-in-out opacity;

      &.visible {
        opacity: 1;
      }
    }
  }

  [menuvisible] .releaseNotifier {
    color: #fff;
  }

  .dropdown {
    --white: #fff;
    --fill: #0a1530;
    position: absolute;
    right: -10px;
    top: 50px;
    text-align: left;
    color: #000;
    background: #fffffff1;
    backdrop-filter: blur(5px) brightness(200%) saturate(200%);
    z-index: 5;
    border-radius: 0.5rem;
    box-shadow:
      rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
      rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
    display: flex;
    flex-direction: column;
    width: min(calc(100vw - 40px), 25rem);
    justify-content: flex-start;
    align-items: stretch;
    line-height: 1.5;
    white-space: initial;
    pointer-events: none;
    opacity: 0;
    transform-style: preserve-3d;
    transform-origin: top center;
    transform: translateY(-10px);
    transition:
      opacity 0.25s ease-in-out,
      transform 0.25s 0.1s ease-in-out;

    &.open {
      pointer-events: all;
      opacity: 1;
      transform: translateY(0px);
    }

    &::before {
      content: ' ';
      position: absolute;
      top: -20px;
      right: 17px;
      border: 10px solid #fffe;
      border-color: transparent transparent #fffe transparent;
    }
  }

  .mainblock {
    & h3 {
      padding: 2rem 2rem 0.5rem;
      margin: 0;
      color: #007ca5;
      text-transform: uppercase;
      font-size: 1rem;
    }

    & ul {
      list-style: none;
      margin: 0;
      display: flex;
      flex-direction: column;
      padding: 0;

      & li {
        margin: 0;
        padding: 0;

        &:last-child a {
          padding-bottom: 2rem;
          border-radius: 0 0 0.5rem 0.5rem;
        }
      }

      & a {
        width: 100%;
        display: block;
        background: none;
        padding: 1rem 2rem;
        color: #0a1530aa;
        box-shadow: none;
        margin: 0;
        transition:
          color 0.25s ease-in-out,
          background 0.25s ease-in-out;

        &:hover,
        &:focus {
          color: var(--fill);
          background: rgba(16, 125, 181, 0.1);
          & b svg {
            opacity: 1;
            transform: translateY(-1px) translateX(0);
          }
        }

        & b {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: var(--fill);
          margin-bottom: 0.25rem;

          & svg {
            vertical-align: middle;
            margin-left: 0.5rem;
            transition:
              opacity 0.25s ease-in-out,
              transform 0.25s ease-in-out;
            transform: translateY(-1px) translateX(-0.5rem);
            opacity: 0;
          }

          & span {
            display: block;
          }
          & time {
            margin-left: auto;
            font-size: 0.8rem;
            opacity: 0.6;
            font-weight: normal;
            white-space: nowrap;
          }
        }

        & p {
          font-size: 0.8rem;
        }
      }
    }
  }

  .badge {
    vertical-align: text-bottom;
    font-size: 0.6em;
    line-height: 10px;
    background: #107db5;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    margin-left: 0.5rem;
    padding: 3px 5px 1px;

    &::before {
      content: '';
      display: inline-block;
      height: 10px;
      vertical-align: middle;
    }
  }

  .fulllink {
    text-align: center;
    display: block;
    font-weight: bold;
    padding: 1rem 2rem;
    margin: 0;
    color: #008300;
    background: rgba(103, 178, 111, 0.1);
    font-size: 1rem;
    box-shadow: none;
    border-radius: 0 0 0.5rem 0.5rem;
    transition: 0.25s ease-in-out background;

    & svg {
      vertical-align: middle;
      margin-left: 0.5rem;
      transition:
        opacity 0.25s ease-in-out,
        transform 0.25s ease-in-out;
      transform: translateY(-1px);
      opacity: 1;
    }

    &:hocus {
      transition: 0.25s ease-in-out background;
      background: rgba(103, 178, 111, 0.3);
    }
  }
`;

const STORAGENAME = 'polypane-seen-release';

function Releases() {
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const [releases, setReleases] = useState(-1);
  const ref = useRef();

  useEffect(() => {
    const latestSeenRelease = localStorage.getItem(STORAGENAME);

    if (latestSeenRelease) {
      const total = releaseData.findIndex((item) => item.version === latestSeenRelease);
      if (total > 0) {
        setReleases(total);
      }
    } else {
      localStorage.setItem(STORAGENAME, releaseData[0].version);
    }

    ReactTooltip.rebuild();
  }, []);

  useOnClickOutside(ref, () => setOpen(false));

  const toggleOpen = (e) => {
    e.preventDefault();
    setOpen(!open);
    if (!open) {
      document.querySelector('#changelog a').focus();
    }
    setOpened(true);
    localStorage.setItem(STORAGENAME, releaseData[0].version);
  };

  return (
    <div ref={ref} className={[styles.releaseWrapper, releases && styles.visible].join(' ')}>
      <button
        type="button"
        className={[styles.releaseNotifier, releases > 0 ? styles.highlight : null].join(' ')}
        onClick={toggleOpen}
        data-tip="Polypane Changelog"
        aria-label="Polypane Changelog"
        data-place="bottom"
        aria-controls="changelog"
        aria-expanded={open}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-bell"
          viewBox="0 0 24 24"
          strokeWidth="1.25"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
          <path className={styles.bell} d="M9 17v1a3 3 0 0 0 6 0v-1" />
        </svg>
        {releases > 0 ? <span className={!open && !opened && styles.visible}>{releases}</span> : null}
      </button>

      <div id="changelog" className={[styles.dropdown, open && styles.open].join(' ')}>
        <div className={styles.mainblock}>
          <h3>Changelog</h3>
          <ul>
            {releaseData.slice(0, 3).map((item, index) => {
              const releaseDate = new Date(item.date);
              return (
                <li key={item.version}>
                  <Link to={item.link}>
                    <b>
                      Polypane {item.version} {index === 0 && <span className={styles.badge}>Latest</span>}
                      <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
                      <time dateTime={item.date}>
                        {releaseDate.toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}
                      </time>
                    </b>
                    <p>{item.description}</p>
                  </Link>
                </li>
              );
            })}
          </ul>
          <Link className={styles.fulllink} to="/docs/changelog/">
            Full Changelog <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Releases;
